import { Component, TemplateRef } from '@angular/core';
import { ToastService } from '../../services/shared/toast/toast.service';
import { NgbToastModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-toast-control',
  standalone: true,
  imports: [NgbToastModule, CommonModule],
  templateUrl: './toast-control.component.html',
  styleUrl: './toast-control.component.scss',
})
export class ToastControlComponent {
  constructor(public toastService: ToastService) {}

  public isTemplate(toast: any) {
    return toast.textOrTpl instanceof TemplateRef;
  }
}
