import { Injectable } from '@angular/core';
import { Observable, Subscription, fromEvent } from 'rxjs';
import { ToastService } from '../toast/toast.service';

@Injectable({
  providedIn: 'root',
})
export class InternetService {
  public onlineEvent: Observable<Event> = fromEvent(window, 'online');
  public offlineEvent: Observable<Event> = fromEvent(window, 'offline');
  public subscriptions: Subscription[] = [];
  public showProgressbar: boolean = false;

  constructor(private _toastService: ToastService) { }

  public subscribeInternetEvents(): void {
    this._subscribeOnlineEvent();
    this._subscribeOfflineEvent();
  }

  private _subscribeOnlineEvent() {
    this.subscriptions.push(
      this.onlineEvent.subscribe((e) => {
        this._toastService.clear();
        this._toastService.showSuccess('Back to online');
      })
    );
  }

  private _subscribeOfflineEvent() {
    this.subscriptions.push(
      this.offlineEvent.subscribe((e) => {
        this._toastService.clear();
        this._toastService.showError(
          'Connection lost! You are not connected to internet');
      })
    );
  }

  public unsubscribeInternetEvents() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
