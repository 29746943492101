import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { NgxSpinnerModule } from 'ngx-spinner';
import { SpinnerService } from './shared/services/shared/spinner/spinner.service';
import { InternetService } from './shared/services/shared/internet/internet.service';
import { Subscription } from 'rxjs';
import { ToastControlComponent } from './shared/controls/toast-control/toast-control.component';
import { CommonModule } from '@angular/common';
import { SharedUserService } from './shared/services/shared/user/shared-user.service';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, NgxSpinnerModule, ToastControlComponent, CommonModule],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  public showProgressbar: boolean = false;
  public name: string = null!;
  public spinnerText: string = null!;

  private _subscriptions: Subscription[] = [];

  constructor(
    private _internetService: InternetService,
    private _spinnerService: SpinnerService,
    private _sharedUserService: SharedUserService,
  ) { }

  public ngOnInit(): void {
    this._subscribeInternetService();
    this._subscribeSpinnerService();
    // this._sharedUserService.get();
  }

  private _subscribeInternetService(): void {
    this._internetService.subscribeInternetEvents();
  }

  public _subscribeSpinnerService(): void {
    let sub = this._spinnerService.spinnerText$.subscribe(
      (text) => (this.spinnerText = text)
    );
    this._subscriptions.push(sub);
  }

  public ngOnDestroy(): void {
    this._internetService.unsubscribeInternetEvents();
    this._subscriptions.forEach(s => s.unsubscribe());
  }
}
