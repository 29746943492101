import { Routes } from '@angular/router';

export const routes: Routes = [
    {
        path: '',
        redirectTo: '/app',
        pathMatch: 'full'
    },
    {
        path: 'app',
        loadChildren: () =>
            import('./modules/organization/organization.module').then(
                (m) => m.OrganizationModule
            ),
    },
];
